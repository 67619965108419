import axios from 'axios';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/Admin/HomeView.vue';
import Store from '@/store/index';
import { getLoginUser } from '@/utility/login';
import { masterChildrenSearchCondtionName } from '@/utility/master-child';
import { masterStaffSearchCondtionName } from '@/utility/master-employees';

export const adminLogin = 'admin-login';
export const tabletLogin = 'tablet-login';
export const employeeLogin = 'employee-login';
export const parentLogin = 'parent-login';
const masterChildren = 'master-children';
const masterChildrenNew = 'master-children-new';
const masterChildrenEdit = 'master-children-edit';
const masterStaff = 'master-employee';
const masterStaffNew = 'master-employee-new';
const masterStaffEdit = 'master-employee-edit';
const masterBase = 'master-base';
const masterBaseNew = 'master-base-new';
const masterBaseEdit = 'master-base-edit';

//  保護者ページ判定用 ※URLの一部に文字列が含まれると保護者用ページと判定
const parentUrlKeys = ['guardian'];

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    path: '/admin',
    meta: { isPublic: true, title: 'ほいくむ(ホーム)' },
    component: () => import(/* webpackChunkName: "admin-frame" */ '../views/Admin/AdminFrame.vue'),
    children: [
      {
        path: '',
        name: '',
        meta: { isPublic: true },
        component: () => import(/* webpackChunkName: "login" */ '../views/Admin/AdminLogin.vue'),
      },
      {
        path: 'login',
        name: adminLogin,
        meta: { isPublic: true },
        component: () => import(/* webpackChunkName: "admin-login" */ '../views/Admin/AdminLogin.vue'),
      },
      {
        path: 'home',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Admin/HomeView.vue'),
      },
      {
        path: 'shift',
        children: [
          {
            path: 'plan',
            component: () => import(/* webpackChunkName: "shift-plan-frame" */ '../views/Admin/Shift/Plan/ShiftPlanFrame.vue'),
            children: [
              {
                path: 'monthly',
                name: 'shift-plan-monthly',
                component: () => import(/* webpackChunkName: "shift-plan-monthly" */ '../views/Admin/Shift/Plan/ShiftPlanMonthly.vue'),
              },
              {
                path: 'daily',
                name: 'shift-plan-daily',
                component: () => import(/* webpackChunkName: "shift-plan-daily" */ '../views/Admin/Shift/Plan/ShiftPlanDaily.vue'),
              },
              {
                path: 'need',
                name: 'shift-plan-need',
                component: () => import(/* webpackChunkName: "shift-plan-need" */ '../views/Admin/Shift/Plan/ShiftPlanNeed.vue'),
              },
            ],
          },
          {
            path: 'results',
            component: () => import(/* webpackChunkName: "shift-results-frame" */ '../views/Admin/Shift/Result/ShiftResultsFrame.vue'),
            children: [
              {
                path: 'monthly',
                name: 'shift-results-monthly',
                component: () =>
                  import(/* webpackChunkName: "shift-results-monthly" */ '../views/Admin/Shift/Result/ShiftResultsMonthly.vue'),
              },
              {
                path: 'daily',
                name: 'shift-results-daily',
                component: () => import(/* webpackChunkName: "shift-results-daily" */ '../views/Admin/Shift/Result/ShiftResultsDaily.vue'),
              },
              {
                path: 'over/:mode',
                name: 'shift-result-over',
                component: () => import(/* webpackChunkName: "shift-result-over" */ '../views/Admin/Shift/Result/ShiftResultOver.vue'),
              },
            ],
          },
        ],
      },
      {
        path: 'children',
        component: () => import(/* webpackChunkName: "children-frame" */ '../views/Admin/Children/ChildrenFrame.vue'),
        children: [
          {
            path: 'monthly',
            name: 'children-monthly',
            component: () => import(/* webpackChunkName: "children-monthly" */ '../views/Admin/Children/ChildrenMonthly.vue'),
          },
          {
            path: 'daily',
            name: 'children-daily',
            component: () => import(/* webpackChunkName: "children-daily" */ '../views/Admin/Children/ChildrenDaily.vue'),
          },
          {
            path: 'over/:mode',
            name: 'children-over',
            component: () => import(/* webpackChunkName: "children-over" */ '../views/Admin/Children/ChildrenOver.vue'),
          },
        ],
      },
      {
        path: 'master',
        component: () => import(/* webpackChunkName: "master-frame" */ '../views/Admin/Master/MasterFrame.vue'),
        children: [
          {
            path: '',
            name: 'master',
            component: () => import(/* webpackChunkName: "master-root" */ '../views/Admin/Master/MasterRoot.vue'),
          },
          {
            path: 'employee',
            children: [
              {
                path: ':id(\\d*)',
                name: masterStaff,
                component: () => import(/* webpackChunkName: "master-employee" */ '../views/Admin/Master/Employee/MasterEmployee.vue'),
              },
              {
                path: 'edit/:id/:stdate',
                name: masterStaffEdit,
                component: () =>
                  import(/* webpackChunkName: "master-employee-edit" */ '../views/Admin/Master/Employee/MasterEmployeeEdit.vue'),
              },
              {
                path: 'new',
                name: masterStaffNew,
                component: () =>
                  import(/* webpackChunkName: "master-employee-new" */ '../views/Admin/Master/Employee/MasterEmployeeNew.vue'),
              },
            ],
          },
          {
            path: 'children',
            children: [
              {
                path: '',
                name: masterChildren,
                component: () => import(/* webpackChunkName: "master-children" */ '../views/Admin/Master/Children/MasterChildren.vue'),
              },
              {
                path: 'edit/:id/:stdate',
                name: masterChildrenEdit,
                component: () =>
                  import(/* webpackChunkName: "master-children-edit" */ '../views/Admin/Master/Children/MasterChildrenEdit.vue'),
              },
              {
                path: 'new',
                name: masterChildrenNew,
                component: () =>
                  import(/* webpackChunkName: "master-children-new" */ '../views/Admin/Master/Children/MasterChildrenNew.vue'),
              },
            ],
          },
          {
            path: 'base',
            children: [
              {
                path: '',
                name: masterBase,
                component: () => import(/* webpackChunkName: "master-base" */ '../views/Admin/Master/Base/MasterBase.vue'),
              },
              {
                path: 'edit/:id/:stdate',
                name: masterBaseEdit,
                component: () => import(/* webpackChunkName: "master-base-edit" */ '../views/Admin/Master/Base/MasterBaseEdit.vue'),
              },
              {
                path: 'new',
                name: masterBaseNew,
                component: () => import(/* webpackChunkName: "master-base-new" */ '../views/Admin/Master/Base/MasterBaseNew.vue'),
              },
            ],
          },
          {
            path: 'events',
            children: [
              {
                path: '',
                name: 'master-events',
                component: () => import(/* webpackChunkName: "master-events" */ '../views/Admin/Master/Events/MasterEvents.vue'),
              },
              {
                path: 'edit/:id',
                name: 'master-events-edit',
                component: () => import(/* webpackChunkName: "master-events-edit" */ '../views/Admin/Master/Events/MasterEventsEdit.vue'),
              },
              {
                path: 'new',
                name: 'master-events-new',
                component: () => import(/* webpackChunkName: "master-events-new" */ '../views/Admin/Master/Events/MasterEventsNew.vue'),
              },
            ],
          },
          {
            path: 'inform',
            children: [
              {
                path: '',
                name: 'master-inform',
                component: () => import(/* webpackChunkName: "master-inform" */ '../views/Admin/Master/Inform/MasterInform.vue'),
              },
              {
                path: 'edit/:id',
                name: 'master-inform-edit',
                component: () => import(/* webpackChunkName: "master-inform-edit" */ '../views/Admin/Master/Inform/MasterInformEdit.vue'),
              },
              {
                path: 'new',
                name: 'master-inform-new',
                component: () => import(/* webpackChunkName: "master-inform-new" */ '../views/Admin/Master/Inform/MasterInformNew.vue'),
              },
            ],
          },
        ],
      },
      {
        path: 'mgmt',
        component: () => import(/* webpackChunkName: "mgmt-frame" */ '../views/Mgmt/MgmtFrame.vue'),
        children: [
          {
            path: '',
            name: 'mgmt',
            component: () => import(/* webpackChunkName: "mtmg-excel" */ '../views/Mgmt/MgmtExcel.vue'),
          },
        ],
      },
      {
        path: 'techno',
        name: 'techno',
        component: () => import(/* webpackChunkName: "techno" */ '../views/Admin/TechnoView.vue'),
      },
    ],
  },
  {
    path: '/tablet',
    meta: { isPublic: true, title: 'ほいくむ(タブレット)' },
    component: () => import(/* webpackChunkName: "tablet-frame" */ '../views/Tablet/TabletFrame.vue'),
    children: [
      {
        path: '',
        name: '',
        meta: { isPublic: true },
        component: () => import(/* webpackChunkName: "tablet-login" */ '../views/Tablet/TabletLogin.vue'),
      },
      {
        path: 'login',
        name: tabletLogin,
        meta: { isPublic: true },
        component: () => import(/* webpackChunkName: "tablet-login" */ '../views/Tablet/TabletLogin.vue'),
      },
      {
        path: 'home-children',
        name: 'tablet-home-children',
        component: () => import(/* webpackChunkName: "tablet-home-children" */ '../views/Tablet/TabletHomeChildren.vue'),
      },
      {
        path: 'home-staffs',
        name: 'tablet-home-staffs',
        component: () => import(/* webpackChunkName: "tablet-home-staffs" */ '../views/Tablet/TabletHomeStaffs.vue'),
      },
    ],
  },
  {
    path: '/employee',
    meta: { isPublic: true, title: 'ほいくむ(スマホ)' },
    component: () => import(/* webpackChunkName: "employee-frame" */ '../views/Employee/EmployeeFrame.vue'),
    children: [
      {
        path: '',
        name: '',
        meta: { isPublic: true },
        component: () => import(/* webpackChunkName: "employee-login" */ '../views/Employee/EmployeeLogin.vue'),
      },
      {
        path: 'login',
        name: employeeLogin,
        meta: { isPublic: true },
        component: () => import(/* webpackChunkName: "employee-login" */ '../views/Employee/EmployeeLogin.vue'),
      },
      {
        path: 'news',
        name: 'employee-news',
        component: () => import(/* webpackChunkName: "employee-news" */ '../views/Employee/EmployeeNews.vue'),
      },
      {
        path: 'input-shift',
        name: 'employee-input-shift',
        component: () => import(/* webpackChunkName: "employee-input-shift" */ '../views/Employee/EmployeeInputShift.vue'),
      },
      {
        path: 'input-shift/:yyyymm',
        name: 'employee-input-shift-monthly',
        component: () => import(/* webpackChunkName: "employee-input-shift-monthly" */ '../views/Employee/EmployeeInputShift.vue'),
      },
      {
        path: 'view-shift-plan',
        name: 'employee-view-shift-plan',
        component: () => import(/* webpackChunkName: "employee-view-shift-plan" */ '../views/Employee/EmployeeViewShiftPlan.vue'),
      },
      {
        path: 'view-shift-result',
        name: 'employee-view-shift-result',
        component: () => import(/* webpackChunkName: "employee-view-shift-result" */ '../views/Employee/EmployeeViewShiftResult.vue'),
      },
      {
        path: 'input-rmethod',
        name: 'employee-input-rmethod',
        component: () => import(/* webpackChunkName: "employee-input-rmethod" */ '../views/Employee/EmployeeInputRMethod.vue'),
      },
      {
        path: 'input-rmethod/:yyyymmdd',
        name: 'employee-input-rmethod-monthly',
        component: () => import(/* webpackChunkName: "employee-input-rmethod-monthly" */ '../views/Employee/EmployeeInputRMethod.vue'),
      },
      {
        path: 'setting',
        name: 'employee-setting',
        component: () => import(/* webpackChunkName: "employee-setting" */ '../views/Employee/EmployeeSetting.vue'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = (to.meta.title as string) ?? 'ほいくむ';

  //  routes内のbeforeEnterで処理しようとすると、removeItemが利かないため ここに記述
  if (from.name === masterChildren && ![masterChildren, masterChildrenNew, masterChildrenEdit].some(v => v === to.name)) {
    sessionStorage.removeItem(masterChildrenSearchCondtionName);
  }
  if (from.name === masterStaff && ![masterStaff, masterStaffNew, masterStaffEdit].some(v => v === to.name)) {
    sessionStorage.removeItem(masterStaffSearchCondtionName);
  }

  //  アクセスログ送信
  const loginUser = getLoginUser();
  if (loginUser) {
    axios.post('/api/accesslogs/log', {
      accessRouteName: to.name as string,
      accessRouteParams: JSON.stringify(to.params),
    });
  }

  if (to.matched.some(record => !record?.meta.isPublic) && !loginUser) {
    const head = to.matched[0].path.replace('/', '');
    const name = head + '-' + 'login';
    next({ name: name, query: { redirect: to.fullPath } });
  } else {
    next();
  }
});

export default router;
