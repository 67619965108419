import axios from 'axios';
import { getLoginUser, updateLoginUser } from './login';
import { alert, authAlert } from '@/utility/messages';
import { axiosConnections } from '@/utility/axios-lib-utilities';

const authErr = 500; //< 認証エラーコード コードを変更したいのならC#側を変更

axios.interceptors.request.use(config => {
  axiosConnections.value++;
  const user = getLoginUser();
  if (user && config.headers) {
    config.headers.Authorization = 'Bearer ' + user.token;
  }
  return config;
});

axios.interceptors.response.use(
  value => {
    axiosConnections.value--;
    updateLoginUser();
    return value;
  },
  async error => {
    axiosConnections.value--;
    updateLoginUser();
    const data = error?.response?.data;
    if (data) {
      const message = typeof data === 'string' ? data : data instanceof Blob ? await data.text() : JSON.stringify(data);
      if (error?.response?.status === authErr && typeof data === 'string' && data.startsWith('Domain.AuthException:')) {
        authAlert('認証エラーが発生しました。ログインし直すと利用できます。\n' + message);
      } else {
        alert(message, 'width: auto; max-width:900px;');
      }
    } else if (error) {
      const messages = [] as string[];
      if (typeof error.message === 'string' && error.message) {
        messages.push(error.message);
      }
      if (typeof error.request?.responseURL === 'string' && error.request.responseURL) {
        messages.push('url: ' + error.request.responseURL);
      }
      alert(messages.join('\n'));
    }
  }
);
