import { komaMin, komaDenom, marginFromMin, marginToMin } from '@/utility/consts/ComProps';

export function komaToText(koma: number): string {
  const komaHH = ('00' + (Math.floor(koma / komaDenom) % 24)).slice(-2);
  const komaMM = ('00' + komaMin * (koma % komaDenom)).slice(-2);
  return `${komaHH}:${komaMM}`;
}

export function textToKoma(time: string): number | undefined {
  const st = time.split(':');
  const res = st.length === 2 ? 4 * Number(st[0]) + Math.floor(Number(st[1]) / 15) : undefined;
  return res;
}

export function komaToText48(koma: number): string {
  const komaHH = ('00' + (Math.floor(koma / komaDenom) % 48)).slice(-2);
  const komaMM = ('00' + komaMin * (koma % komaDenom)).slice(-2);
  return `${komaHH}:${komaMM}`;
}

//  fromPunchTimeから調整後の時刻を求め、最大の選べる時刻を取得する
export function getSelectableOutPunchTime(punchTimeFrom: Date): Date {
  const fromDt = toResultInPunchTime(punchTimeFrom);
  const toDt = new Date(fromDt);
  toDt.setHours(toDt.getHours() + 24);
  toDt.setMinutes(toDt.getMinutes() - marginToMin);
  return toDt;
}

//  調整後の打刻時刻を取得 In
export function toResultInPunchTime(inPunchTime: Date): Date {
  const dt = new Date(inPunchTime);
  dt.setMinutes(dt.getMinutes() + (komaMin - marginFromMin));
  dt.setMinutes(Math.floor(dt.getMinutes() / komaMin) * komaMin); //< 二回に別ける
  dt.setSeconds(0);
  return dt;
}
export function toKoma(date: Date): number {
  return Math.floor((date.getHours() * 60 + date.getMinutes()) / komaMin);
}
